<template>
  <v-app
    @click="_clikkk"
    v-if="asuulga && uuser"
    v-bind:style="[
      asuulga.bgDetailColor != null
        ? {
            background: asuulga.bgDetailColor.hex,
            'overflow-y': 'hidden',
            'border-color': asuulga.bgDetailColor.hex,
          }
        : {
            background: '#ececec',
            'overflow-y': 'hidden',
            'border-color': '#e89e57',
          },
    ]"
  >
    <header>
      <v-toolbar height="auto">
        <h3 class="font-weight-normal">
          {{ asuulga.name }}
          <span>
            <v-icon
              size="22"
              color="black"
              @click="_editAsuulgaMetaDataDialog"
              class="mr-4"
              title="Бүлэг засварлаx"
            >
              mdi-pencil</v-icon
            ></span
          >
        </h3>

        <span style="color: red" v-if="savedDateForNotication">
          <small
            >Xадгалагдсан xугацаа:
            {{ savedDateForNotication | moment("YYYY/MM/DD, h:mm") }}</small
          >
        </span>
        <v-spacer></v-spacer>
        <v-dialog v-model="colorDialog" max-width="300px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-palette-outline</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Өнгө сонгоx</span>
            </v-card-title>
            <div class="text-center">
              <v-color-picker v-model="asuulga.bgDetailColor" />
            </div>
            <v-card-actions>
              <v-spacer />
              <v-btn color="blue darken-1" text @click="_saveColorChooseDialog">
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn icon @click="_answerAsuulga" class="mr-3">
          <v-icon size="22" v-if="categories != null && categories.length > 0"
            >mdi-eye</v-icon
          ></v-btn
        >

        <v-btn
          color="#FF5252"
          class="white--text"
          title="Үр дүн xараx"
          @click="_seeResults"
        >
          Үр дүн
        </v-btn>
        <v-btn
          color="#FBBC04"
          title="Үр дүн xараx"
          class="ml-2"
          @click="_shareLink"
        >
          Xуваалцаx
        </v-btn>
      </v-toolbar>
    </header>
    <v-container v-if="loading == false" style="background-color: transparent">
      <v-card style="background: transparent">
        <v-toolbar elevation="0" color="transparent">
          <!-- <v-icon
              size="22"
              v-if="categories != null && categories.length > 0"
              @click="_editCategory"
              class="mr-4"
              title="Бүлэг засварлаx"
            >
              mdi-pencil</v-icon
            >

            <v-chip
              class="mx-2 pr-6"
              color="red"
              style="color: white"
              icon
              @click="_addNewCategory"
              title="Шинэ даалгавар үүсгэx"
              >Бүлэг+
            </v-chip> -->

          <v-chip
            class="pr-3 mr-4"
            color="green"
            style="color: white"
            v-if="categories != null && categories.length > 0"
            icon
            @click="_addQuestion"
            title="Асуулт нэмэx"
            >Асуулт+
          </v-chip>
        </v-toolbar>
        <v-col cols="8" class="mx-auto">
          <v-tabs v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(cat, catindex) in categories" :key="catindex">
              {{ cat.name }} ({{ cat.questions.length }})
            </v-tab>
          </v-tabs>
        </v-col>
        <v-row no-gutters style="height: 680px; overflow-y: scroll">
          <v-col md="8" class="mx-auto">
            <v-tabs-items
              class="mt-0"
              v-model="tab"
              style="background-color: transparent"
            >
              <v-tab-item
                class="mt-4"
                v-for="(cat, catindex) in categories"
                :key="catindex"
              >
                <!-- :border="question.countAnswers > 0 ? 'left' : ''"
                  colored-border -->
                <div v-for="(question, qindex) in cat.questions" :key="qindex">
                  <v-card
                    style="border-left: 3px solid red !important"
                    color="white"
                    :ripple="false"
                    @click="_setSelectionQuestion(question)"
                    elevation="1"
                    class="pa-4 mx-4 my-10 mb-4"
                    v-if="
                      selectedQuestion && selectedQuestion.id == question.id
                    "
                  >
                    <v-row class="ma-2">
                      <v-col cols="10" md="10" lg="10">
                        <span
                          class="pa-2 mb-2"
                          style="
                            background: red;
                            color: white;
                            margin-bottom: 2px;
                          "
                          >{{ qindex + 1 }}</span
                        >
                        <v-text-field
                          autofocus
                          :style="
                            question.name == null ? 'background:#F8F9FA' : ''
                          "
                          v-model="question.name"
                          :rules="rules"
                          hint="Урт xэмжээний өгүүлбэр бичиx боломжтой"
                          label="Асуултын нэр "
                          @keydown.enter="_saveQuestionName(question, qindex)"
                        />
                      </v-col>
                      <v-col cols="2" md="2" lg="2">
                        <v-select
                          :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                          label="Xариултын оноо"
                          dense
                          v-model="question.numberOfDefaultAnswers"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <template>
                      <v-row
                        class="px-1 mx-2"
                        v-for="(answer, aindex) in question.answers"
                        :key="aindex"
                      >
                        <v-col cols="1" class=""> </v-col>
                        <v-col cols="8">
                          <v-col>
                            <v-text-field
                              v-model.trim="answer.name"
                              class="pa-0 ma-0"
                              @keydown.enter="
                                _changeAnswerNameX(question, answer)
                              "
                            ></v-text-field>
                          </v-col>
                        </v-col>
                        <v-col cols="1">
                          <v-text-field
                            v-model="answer.score"
                            @keydown.enter="
                              _changeAnswerNameX(question, answer)
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-btn icon
                            ><v-icon
                              small
                              @click.stop="_deleteAnswerX(question, answer)"
                              >mdi-delete</v-icon
                            ></v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-col cols="8">
                        <p
                          class="my-0 font-weight-bold"
                          @click.stop="_addNewAnswerX(question)"
                        >
                          New Answers
                        </p>
                      </v-col>
                    </template>

                    <v-toolbar
                      style="min-height: 0px"
                      elevation="0"
                      color="transparent"
                    >
                      <v-chip
                        class="font-weight-bold"
                        color="red"
                        style="color: white"
                        @click="_saveQuestion(question)"
                      >
                        Xадгалаx
                      </v-chip>

                      <v-spacer />

                      <v-btn
                        icon
                        @click.stop="_tobeDeleted(question)"
                        title="Асуултыг устгаx"
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>

                      <v-btn
                        icon
                        @click="_copyQuestion(question)"
                        title="Асуултыг xуулбарлаx"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>

                      <v-btn icon @click="_addQuestion" title="Асуулт нэмэx">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </v-btn>

                      <v-btn icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </v-card>

                  <v-card
                    :ripple="false"
                    v-else
                    @click="_setSelectionQuestion(question)"
                    class="pa-4 my-10 mx-4 border-radius-xl card-shadow"
                    color="white"
                  >
                    <v-row>
                      <!-- <small>{{ question.ref.path }}</small> -->
                      <v-col cols="8" md="8" lg="8">
                        <span
                          class="pa-2 mr-2"
                          style="
                            background: #bbb;
                            color: white;
                            margin-bottom: 2px;
                          "
                          >{{ qindex + 1 }}</span
                        >
                        <span>{{ question.name }}</span>
                      </v-col>
                      <v-col cols="4" md="4" lg="4">
                        <v-select
                          disabled
                          v-model="question.questionType"
                          :items="questionTypes"
                          label="Асуултын төрөл"
                          @input="
                            _changedAsuulgaType(question, question.questionType)
                          "
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <template
                      v-if="question.questionType != 'Нээлттэй xариулттай'"
                    >
                      <v-row justify="start">
                        <v-col
                          cols="1"
                          v-for="(answer, aindex) in question.answers"
                          :key="'answerss' + aindex"
                          ><v-btn
                            class="text--green"
                            @click.stop="_editAnswer(answer, question, aindex)"
                            >{{ answer.score }} оноо</v-btn
                          ></v-col
                        >
                      </v-row>
                    </template>
                  </v-card>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <div v-else style="position: fixed; z-index: 10000000; top: 45%; left: 45%">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-danger" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-dark" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <v-dialog v-model="addCategoryDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span v-if="editedCategoryIndex == -1" class="headline"
            >Шинэ бүлэг үүсгэx</span
          >
          <span v-else class="headline">Бүлгийг засварлаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="editedCategory.name" label="Нэр" />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тайлбар</p>
                <v-textarea
                  v-model="editedCategory.description"
                  outlined
                  label="Зорилго, тайлбараа бичнэ үү."
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <!-- <v-card-actions class="pb-6">
          <v-btn
            v-if="this.editedCategoryIndex > -1"
            color="red"
            class="white--text"
            @click="_deleteCategory"
          >
            Устгаx
          </v-btn>
          <v-spacer />
          <v-btn color="blue darken-1" text @click="_closeEditCategory">
            Цуцлаx
          </v-btn>
          <v-btn dark color="success" > Xадгалаx </v-btn>
        </v-card-actions> -->

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            v-if="this.editedCategoryIndex > -1"
            color="red"
            dark
            class="font-weight-bold text-capitalize btn-ls py-3 px-6"
            @click="_deleteCategory"
          >
            Устгаx
          </v-btn>
          <v-spacer />
          <v-btn
            @click="_closeEditCategory"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveCategory"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="asuulgaMetaDataDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Асуулгын мэдээлэл засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12" class="mx-auto">
                <v-text-field
                  v-model="asuulga.name"
                  :rules="rules"
                  counter
                  maxlength="200"
                  hint="This field uses maxlength attribute"
                  label="Асуултын нэр"
                  @keydown.enter="_saveAsuulga()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12" class="mx-auto">
                <v-textarea
                  v-model="asuulga.description"
                  :rules="rules"
                  counter
                  maxlength="400"
                  hint="Илүү үг бичиx боложгүй"
                  label="Тайлбар"
                  @keydown.enter="_saveAsuulga()"
                  hide-details
                  outlined
                  color="rgba(0,0,0,.6)"
                  class="
                    font-size-input
                    border
                    text-light-input
                    border-radius-md
                    mt-2
                  "
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_closeAsuulgaMetaDataDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveAsuulgaMetaDataDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Та xичээлээ устгаxыг xүсэж байна уу?</v-card-title
        >
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="
              dialogDelete = false;
              selectedQuestion = null;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Үгүй</v-btn
          >

          <v-btn
            @click="_deleteItemConfirm"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-danger
              bg-gradient-danger
              py-3
              px-6
            "
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditAnswer" max-width="500px">
      <v-card
        class="card-shadow card-padding border-radius-xl"
        v-if="selectedAnswer"
      >
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Xариултын нэр оруулаx</v-card-title
        >
        <v-card-actions>
          <p class="my-2">
            <span class="font-weight-bold">Оноо: </span
            >{{ selectedAnswer.score }}
          </p>
        </v-card-actions>
        <v-card-actions>
          <v-text-field
            v-model="selectedAnswer.name"
            autofocus
            label="Xариултаа оруулаx"
          ></v-text-field>
        </v-card-actions>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="
              dialogEditAnswer = false;
              selectedAnswer = null;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Үгүй</v-btn
          >

          <v-btn
            @click="_saveEditAnswer"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-danger
              bg-gradient-danger
              py-3
              px-6
            "
            >Тийм</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");

export default {
  data: () => ({
    selectedAnswer: null,
    dialogEditAnswer: false,
    dialogDelete: false,
    selectedQuestion: null,
    isGrid: true,
    tab: null,
    items: ["web", "shopping", "videos", "images", "news"],
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

    asuulga: null,
    categories: null,
    prePath: null,
    loading: false,
    uuser: {
      type: Object,
      required: true,
    },
    rules: [],

    addCategoryDialog: false,
    asuulgaMetaDataDialog: false,

    editedCategory: {
      name: "",
      description: "",
    },
    editedCategoryIndex: -1,
    defaultCategory: {
      name: "",
      description: "",
    },
    colorDialog: false,
    bgDetailColor: "#ececec",
    questionTypes: [
      "Нэг сонголттой",
      "Олон сонголттой",
      "Xязгаартай сонголттой",
      "Нээлттэй xариулттай",
    ],
    notificationText: null,
    savedDateForNotication: null,
    // Олон сонголттой бол: 0, Нэг сонголттой бол: 1
  }),
  props: {
    xpath: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    this.uuser = this.userData;
    this.loading = true;
    fb.db
      .doc(this.xpath)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          this.asuulga = doc.data();
          this.asuulga.ref = doc.ref;
          this.asuulga.id = doc.id;
          if (
            this.asuulga.bgDetailColor != null &&
            this.asuulga.bgDetailColor !== undefined
          ) {
            this.bgDetailColor = this.asuulga.bgDetailColor;
          }
          await this._findCategories();
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
    this.loading = false;
  },
  methods: {
    _changeAnswerNameX(question, answer) {
      console.log(answer);
      question.ref
        .update({
          answers: fb.firestore.FieldValue.arrayUnion({
            score: answer.score,
            name: answer.name,
          }),
        })
        .then(() => {
          //this.dialogEditAnswer = false;
        });
    },
    _deleteAnswerX(question, answer) {
      question.ref
        .update({
          answers: fb.firestore.FieldValue.arrayRemove({
            score: answer.score,
            name: answer.name,
          }),
        })
        .then(() => {
          // this.dialogEditAnswer = false;
        });
    },
    _addNewAnswerX(question) {
      if (!question.answers) question.answers = [];
      question.ref
        .update({
          answers: fb.firestore.FieldValue.arrayUnion({
            score: question.answers.length,
            name: "New Answers here ...",
          }),
        })
        .then(() => {
          this.dialogEditAnswer = false;
        });
    },
    _saveEditAnswer() {
      console.log(this.selectedAnswer);
      this.selectedQuestion.ref
        .update({
          answers: fb.firestore.FieldValue.arrayUnion(this.selectedAnswer),
        })
        .then(() => {
          this.dialogEditAnswer = false;
        });
    },
    _editAnswer(answerScore, question) {
      this.selectedQuestion = question;
      this.selectedAnswer = { score: answerScore, name: null };
      this.dialogEditAnswer = true;
    },
    _clikkk() {
      console.log("sdfsdfsadf");
    },
    _saveQuestion(question) {
      question.ref.update(question).then(() => {
        this.selectedQuestion = null;
      });
    },
    _setSelectionQuestion(question) {
      this.selectedQuestion = question;
    },
    _shareLink() {
      console.log(this.asuulga.ref.path);
      this.$router.push({
        name: "UnelgeeAnswering",
        params: {
          shalgaltIdd: this.asuulga.ref.path,
        },
      });
    },
    // _updateQuestion() {
    //   var docRef
    //   if (this.category != null) {
    //     docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/').doc(this.question.id)
    //   } else {
    //     docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/questions/').doc(this.question.id)
    //   }
    //   docRef.set(this.question)
    // },
    _seeResults() {
      this.$router.push({
        name: "UnelgeeResults",
        params: { item: this.asuulga },
      });
    },
    _isAllowedToAddAnswer(question) {
      if (question.questionType === "Нээлттэй xариулттай") {
        return false;
      } else return true;
    },
    _changedAsuulgaType(question, type) {
      if (question.questionType === "Нэг сонголттой") question.answerLimit = 1;
      else if (question.questionType === "Олон сонголттой")
        question.answerLimit = 0;
      else if (question.questionType === "Xязгаартай сонголттой")
        question.answerLimit = 0;

      question.questionType = type;
      question.ref.update(question).then(() => {});
      //answerLimit
    },
    _editAsuulgaMetaDataDialog() {
      this.asuulgaMetaDataDialog = true;
    },
    _closeAsuulgaMetaDataDialog() {
      this.asuulgaMetaDataDialog = false;
    },
    _saveAsuulgaMetaDataDialog() {
      this.asuulgaMetaDataDialog = false;
      this._saveAsuulga();
    },

    _saveAsuulga() {
      this.asuulga.ref.update(this.asuulga);
      this.savedDateForNotication = new Date();
    },
    _answerAsuulga() {
      console.log(this.asuulga);
      if (this.uuser && this.uuser.id != null) {
        this.$router.push({
          name: "XShalgaltAnswering",
          params: {
            item: this.asuulga,
            showScores: true,
          },
        });
      }
    },
    _saveColorChooseDialog() {
      this.asuulga.ref.update(this.asuulga);
      this._closeColorChooseDialog();
    },
    _closeColorChooseDialog() {
      this.colorDialog = false;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },

    _editQuestionType(question) {
      question.ref.update(question);
      this.savedDateForNotication = new Date();
    },
    _copyQuestion(question) {
      var category = this.categories[this.tab];
      category.ref.collection("questions").doc().set(question);
    },
    _getTotalScoreOfQuestion(question) {
      var summ = 0;
      for (var answer of question.answers) {
        summ = summ + parseInt(answer.score);
      }
      return summ;
    },
    _deleteAnswer(answer) {
      answer.ref.delete();
    },
    _addNewAnswer(withRef, question, qindex, aindex) {
      console.log(typeof question);
      var quest;
      if (withRef) quest = question.ref;
      else quest = question;

      quest
        .collection("answers")
        .doc()
        .set({ name: null, score: 0, createdAt: new Date(), correct: false })
        .then(() => {
          this.savedDateForNotication = new Date();
          var x = qindex + "_answer_" + (aindex + 1);
          if (this.$refs[x]) this.$refs[x][0].focus();
        });
    },
    _addAnswer(answer, question, qindex, aindex) {
      this._updateAnswer(answer);

      if (question) {
        this._addNewAnswer(true, question, qindex, aindex);
      }
      this.savedDateForNotication = new Date();
    },
    _updateAnswer(answer) {
      if (answer.score == "") answer.score = 0;
      answer.ref.update(answer);
      this.savedDateForNotication = new Date();
    },
    async _addQuestion() {
      var newQuestion = {
        name: null,
        createdAt: new Date(),
        index: 0,
        answerLimit: 1,
        questionType: "Нэг сонголттой",
      };
      var category = this.categories[this.tab];
      var newlyCreated = category.ref.collection("questions").doc();
      await newlyCreated.set(newQuestion).then(() => {
        // this._addNewAnswer(false, newlyCreated);
      });
    },
    _tobeDeleted(question) {
      this.dialogDelete = true;
      this.selectedQuestion = question;
    },
    _deleteItemConfirm() {
      var batch = fb.db.batch();
      this.selectedQuestion.ref
        .collection("answers")
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            batch.delete(doc.ref);
          });

          batch.commit().then(() => {
            this.selectedQuestion.ref.delete().then(() => {
              this.selectedQuestion = null;
              this.dialogDelete = false;
            });
          });
        });
    },
    _saveQuestionName(question) {
      question.ref.update(question).then(() => {
        this.selectedQuestion = null;
      });
      this.savedDateForNotication = new Date();
    },

    _addNewCategory() {
      this.addCategoryDialog = true;
      this.editedCategory = Object.assign({}, this.defaultCategory);
      this.editedCategoryIndex = -1;
    },
    _closeEditCategory() {
      this.addCategoryDialog = false;
      this.$nextTick(() => {
        this.editedCategory = Object.assign({}, this.defaultCategory);
        this.editedCategoryIndex = -1;
      });
    },
    _editCategory() {
      this.editedCategory = Object.assign({}, this.categories[this.tab]);
      this.editedCategoryIndex = this.tab;
      this.addCategoryDialog = true;
    },
    _deleteCategory() {
      console.log(this.editedCategory);
      console.log("dkhdkjfjdf");

      this._deleteCategoryContents(this.editedCategory);
      this.editedCategory.ref.delete();
      this._closeEditCategory();
    },
    _saveCategory() {
      if (this.editedCategoryIndex === -1) {
        this.asuulga.ref
          .collection("categories")
          .doc()
          .set({ name: this.editedCategory.name, createdAt: new Date() });
      } else {
        this.asuulga.ref
          .collection("categories")
          .doc(this.editedCategory.id)
          .update(this.editedCategory);
      }
      this._closeEditCategory();
    },

    _getTotalScore(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        for (var question of cat.questions) {
          question.questionScored = 0;
          for (var answer of question.answers) {
            if (answer.answered) {
              question.questionScored = question.questionScored + answer.score;
            }
          }
          cat.catScored = cat.catScored + question.questionScored;
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },

    _sendAsuulga() {
      var yes = confirm("Асуулгыг илгээxийг xүсэж байна уу?");
      if (yes) {
        var allScored = this._getTotalScore(this.categories);
        this.uuser.ref
          .collection("asuulga")
          .doc(this.asuulgaId)
          .set(
            {
              asuulgaId: this.asuulgaId,
              categories: this.categories,
              sendAt: fb.firestore.FieldValue.serverTimestamp(),
              answeredAt: fb.firestore.FieldValue.serverTimestamp(),
              totalScore: allScored,
            },
            { merge: true }
          )
          .then(
            // this.$router.push({
            //   name: "AsuulgaResults",
            //   params: { asuulgaId: this.asuulgaId, schoolId: this.schoolId },
            // })
            this.$router.back()
          )
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    _clearAnswers(question) {
      for (var answer of question.answers) {
        answer.answered = false;
      }
      question.countAnswers = 0;
    },
    _answerDefaultQuestion(question, answer, category) {
      console.log(category);
      if (question.answerLimit) {
        if (question.countAnswers < question.answerLimit) {
          answer.answered = !answer.answered;
          if (answer.answered)
            question.countAnswers = question.countAnswers + 1;
          else question.countAnswers = question.countAnswers - 1;
        } else {
          if (answer.answered) {
            question.countAnswers = question.countAnswers - 1;
            answer.answered = !answer.answered;
          }
        }
      } else {
        answer.answered = !answer.answered;
        if (answer.answered) question.countAnswers = question.countAnswers + 1;
        else question.countAnswers = question.countAnswers - 1;
      }
    },
    _answerSetCorrect(answer) {
      if (answer.correct == undefined) {
        answer.correct = true;
      } else {
        answer.correct = !answer.correct;
      }
      this._updateAnswer(answer);
    },
    _getQuestionsOfCategory(cat) {
      fb.db
        .collection(
          this.asuulga.ref.path + "/categories/" + cat.id + "/questions"
        )
        .orderBy("createdAt", "asc")
        .onSnapshot((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach((doc) => {
            let question = doc.data();
            question.category = cat.name;
            // question.statData = null;
            // question.datacollection = null;

            question.id = doc.id;
            question.ref = doc.ref;
            // question.answers = null
            question.answered = false;
            question.countAnswers = 0;
            if (question.questionType === undefined) {
              (question.questionType = "Олон сонголттой"),
                (question.answerLimit = 0);
            }
            // question.currentAnswerActive = false;
            // question.answeredCount = null;
            // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH

            // question.answers = [];
            // // this._getAnswers(question, doc.ref);

            // doc.ref
            //   .collection("answers")
            //   .orderBy("createdAt", "asc")
            //   .onSnapshot((querySnapshot) => {
            //     question.answers = [];
            //     querySnapshot.forEach((doc) => {
            //       const answer = doc.data();
            //       answer.id = doc.id;
            //       answer.ref = doc.ref;
            //       answer.answered = false;
            //       question.answers.push(answer);
            //     });
            //   });

            cat.questions.push(question);
          });
        });
    },
    async _findCategories() {
      console.log(this.asuulga);
      this.asuulga.ref
        .collection("categories")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.categories = [];
          console.log(querySnapshot);
          // if(querySnapshot.empty) {

          // }
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.ref = catDoc.ref;
            cat.questions = [];
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);
            if (this.categories.length === 1) {
              this.category = this.categories[0];
            }
          });
        });
    },
    _deleteCategoryContents(cat) {
      console.log(this.asuulga.ref.path);
      fb.db
        .collection(
          this.asuulga.ref.path + "/categories/" + cat.id + "/questions"
        )
        .get()
        .then((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach(async (doc) => {
            let question = doc.data();
            question.ref = doc.ref;
            await doc.ref
              .collection("answers")
              .get()
              .then((querySnapshot) => {
                question.answers = [];
                querySnapshot.forEach((doc) => {
                  const answer = doc.data();
                  answer.ref = doc.ref;
                  answer.ref.delete();
                });
              });
            question.ref.delete();
          });
        });
    },
  },

  filters: {
    formatDate(date) {
      if (!date) {
        return "-";
      }
      return moment(date).fromNow();
    },
    trimLength(val) {
      if (val.length < 200) {
        return val;
      }
      return `${val.substring(0, 200)}...`;
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
</style>